const decisionOptions = [
	{
		label: 'Good',
		value: 'good',
		colour: 'emerald'
	},
	{
		label: 'Error',
		value: 'error',
		colour: 'red'
	},
	{
		label: 'Discuss',
		value: 'discuss',
		colour: 'blue'
	},
	{
		label: 'Event',
		value: 'event',
		colour: 'blue'
	}
];
export default decisionOptions;
